import React from 'react';

import Download from 'components/Download';
import Layout from 'components/Layout';

const DownloadPage = () => (
  <Layout>
    <Download />
  </Layout>
);

export default DownloadPage;
